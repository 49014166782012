<template>
    <v-card v-if="visible" class="explore-wrapper">
        <v-toolbar color="default" dark dense>
            <v-toolbar-title>
                <small>
                    <span v-show="status===1">Načítám... | </span>
                    Nejbližší síly a prostředky <span v-show="status> 1">k {{ datetime }}</span>
                </small>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pl-3">
                <v-btn fab x-small color="red" @click="close">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card tile v-if="status > 1">
            <v-toolbar dense>
                <v-spacer></v-spacer>
                <v-toolbar-title class="text-h4">
                    {{ district.name }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <div class="pa-6 text-center" v-if="status < 5">
                Načítám časovou dostupnost...
            </div>
            <v-simple-table v-if="status > 4">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th>Typ</th>
                        <th>Doba dojezdu</th>
                        <th>Vzdálenost</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, key) in items" :key="key">
                        <td>
                            <span v-if="item.type === 'vehicle'">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <event-job-icon v-bind:resource="item.resource" v-bind:job="item.eventJob"
                                                        v-on="on"></event-job-icon>
                                    </template>
                                    <span v-html="item.tooltip"></span>
                                </v-tooltip>
                            </span>
                            <span v-if="item.type === 'base'">
                                <base-icon :base="baseById(item.id)"></base-icon>
                            </span>
                        </td>
                        <td>
                            <span class="text-h6" v-html="item.availability"></span>
                            <span v-show="item.durationIncrease" class="font-weight-regular"
                                  v-html="item.durationIncrease"></span>
                        </td>
                        <td>
                            <span class="text-h6" v-show="status === 5"> {{
                                    Math.round(item.distance / 100) / 10
                                }}</span><small> km</small>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-card>
</template>

<script>

import {appBus} from "@/main";
import {mapGetters, mapState} from "vuex";
import EventJobIcon from "@/components/panel/sidebar/event/JobIcon.vue";
import BaseIcon from "@/components/panel/sidebar/event/BaseIcon.vue";

export default {
    components: {BaseIcon, EventJobIcon},
    data() {
        return {}
    },
    computed: {
        ...mapState({
            status: state => state.sap.status,
            datetime: state => state.sap.datetime,
            district: state => state.sap.districts[0],
            items: state => state.sap.items,
        }),
        ...mapGetters('sap', {
            visible: 'visible',
        }),
        ...mapGetters('bases', {
            baseById: 'byId',
        }),
        ...mapGetters('eventJobs', {
            jobStatus: 'getByUid',
        }),
    },
    methods: {
        backgroundColor(item) {
            if (item.type === 'base') {
                return 'blue';
            } else {
                return this.jobStatus(item.status).background;
            }
        },
        contentColor(item) {
            if (item.type === 'base') {
                return 'white';
            } else {
                return this.jobStatus(item.status).content;
            }
        },
        icon(item) {
            if (item.type === 'base') {
                return "home";
            }
            return item.icon
        },
        close() {
            appBus.$emit('ON_SAP_COMPONENT_END');
        },
    },
}
</script>
