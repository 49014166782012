<template>
    <v-tooltip left content-class="map-tooltip-wrapper">
        <template v-slot:activator="{ on }">
            <div  v-on="on"  class="base-icon">
                <span>{{ base.code }}</span>
            </div>
        </template>
        <tooltip-content :tooltip="tooltip"></tooltip-content>
    </v-tooltip>
</template>

<script>

import TooltipContent from "@/components/ui/TooltipContent.vue";

export default {
    name: 'base-icon',
    components: {TooltipContent},
    props: {
        base: Object,
    },
    computed: {
        tooltip() {
            return {
                title:'Požární stanice',
                content: this.base.name,
            }
        }
    },
}
</script>
